import styles from './index.module.css'
import { useCallback, useEffect, useState } from 'react'
import RegistrationsMain from '../RegistrationsMain'
import RegistrationsQuickLogin from '../RegistrationsQuickLogin'
import RegistrationsPasswordLogin from '../RegistrationsPasswordLogin'
import RegistrationsCoupons from '../RegistrationsCoupons'
import RegistrationsRemainder from '../RegistrationsRemainder'
import RegistrationsActivate from '../RegistrationsActivate'
import RegistrationsSubscribeSMS from '../RegistrationsSubscribeSMS'
import RegistrationsAuthCode from '../RegistrationsAuthCode'
import { RegistrationsStatus } from '../../const'
import registrationsTrack from '../../RegistrationsTrack'
import { useUI } from '@components/ui/context'
import { useProfile } from '@components/common/ProfileContext'
import RegistrationsAuthCodeSuccess from '../RegistrationsAuthCodeSuccess'

const RegistrationsModel = ({
  data,
  onClose,
  gs,
  locale,
  globalCountry,
  showGoogleLoginCoupon,
  googleLoginCoupons,
}) => {
  const { registrationsModelStatus, registrationsOptions } = useUI()
  const [registrationsStatus, setRegistrationsStatus] = useState(
    registrationsModelStatus || RegistrationsStatus.MAIN
  )

  useEffect(() => {
    setRegistrationsStatus(registrationsModelStatus || RegistrationsStatus.MAIN)
  }, [registrationsModelStatus])

  const [coupons, setCoupons] = useState([])
  const [registrationsFillEmail, setRegistrationsFillEmail] = useState('')
  const [fillEmail, setFillEmail] = useState('')
  const [rsqlFillEmail, setRsqlFillEmail] = useState('')
  const [rsrFillEmail, setRsrFillEmail] = useState('')
  const [subscribe, setSubscribe] = useState(false)
  const [backType, setBackType] = useState('')
  const s = { ...styles, ...gs }

  const { profile } = useProfile()
  useEffect(() => {
    if (profile?.email) {
      setFillEmail(profile?.email)
    }
  }, [profile?.email])

  const handleQuickLogin = useCallback((email) => {
    setFillEmail(email)
    setRegistrationsStatus(RegistrationsStatus.QUICK_LOGIN)
  }, [])

  const handleCreateAccount = useCallback((email) => {
    setRegistrationsStatus(RegistrationsStatus.MAIN)
    setRegistrationsFillEmail(email)
  }, [])

  const handleQuickLoginSuccess = useCallback((email) => {
    setRsrFillEmail(email)
    setRegistrationsStatus(RegistrationsStatus.REMAINDER)
  }, [])

  const handlePasswordLogin = useCallback((email) => {
    setRsqlFillEmail(email)
    setRegistrationsStatus(RegistrationsStatus.PASSWORD_LOGIN)
  }, [])

  const handleRegistrationsSuccess = useCallback(
    (res, email, subscribe) => {
      const { coupons } = res.data
      setCoupons(coupons)
      setFillEmail(email)
      if (registrationsOptions?.memberRegistration) {
        setRegistrationsStatus(RegistrationsStatus.AUTH_CODE)
        return
      }
      setSubscribe(subscribe)
      setRegistrationsStatus(
        locale === 'us'
          ? RegistrationsStatus.SUBSCRIBE_SMS
          : RegistrationsStatus.COUPONS
      )
    },
    [locale, registrationsOptions]
  )

  const handleRegistrationsError = useCallback(
    (res, email) => {
      console.log(res, 'res')
      setFillEmail(email)
      // 注册失败
      if (res.status === 422) {
        // 已注册
        if (registrationsOptions?.memberRegistration) {
          setRegistrationsStatus(RegistrationsStatus.PASSWORD_LOGIN)
          return
        }
        setRegistrationsStatus(RegistrationsStatus.QUICK_LOGIN)
      }
    },
    [registrationsOptions]
  )

  // 在用户点击提交验证码环境，设置一个标识，防止用户在未刷新激活状态后跳转了页面
  // 在返回上一页后useProfile会根据此步骤种下的标识进行刷新用户激活状态
  const handleSetFlag = useCallback(() => {
    if (profile?.activated === false) {
      try {
        localStorage.userActivedFlag = JSON.stringify({
          href: window.location.href,
          activated: false,
        })
      } catch (e) {
        console.warn('auth code set flag error:' + e)
      }
    }
  }, [profile?.activated])

  useEffect(() => {
    if (showGoogleLoginCoupon && googleLoginCoupons) {
      setCoupons(googleLoginCoupons)
      setRegistrationsStatus(RegistrationsStatus.COUPONS)
    }
  }, [googleLoginCoupons, showGoogleLoginCoupon])

  return (
    <div
      aria-label="modal"
      className={s.registrations_model}
      aria-labelledby="registrations-model"
      aria-describedby="registrations-model"
    >
      <div className={s.registrations_model_wrap}>
        <button
          className={s.rsm_close}
          tag="Auto-00000619"
          style={
            registrationsStatus === RegistrationsStatus.MAIN &&
            registrationsOptions?.memberRegistration
              ? { color: '#fff' }
              : {}
          }
        >
          <CloseIcon onClose={() => onClose(registrationsStatus)} />
        </button>
        {registrationsStatus === RegistrationsStatus.MAIN && (
          <RegistrationsMain
            data={{ ...data?.registrationsMain, globalCountry }}
            gs={gs}
            // 验证邮箱发送成功
            onSendVerifyEmailSuccess={() => {
              setBackType('main')
              setRegistrationsStatus(RegistrationsStatus.ACTIVATE)
            }}
            registrationsFillEmail={registrationsFillEmail}
            onQuickLogin={handleQuickLogin}
            onRegistrationsSuccess={handleRegistrationsSuccess}
            onRegistrationsError={handleRegistrationsError}
            onRegistrationsVerify={(email) => {
              setFillEmail(email)
            }}
            onPasswordLogin={handlePasswordLogin}
          />
        )}
        {registrationsStatus === RegistrationsStatus.QUICK_LOGIN && (
          <RegistrationsQuickLogin
            fillEmail={fillEmail}
            data={data?.registrationsQuickLogin}
            gs={gs}
            onPasswordLogin={handlePasswordLogin}
            onQuickLoginSuccess={handleQuickLoginSuccess}
            onCreateAccount={handleCreateAccount}
            onQuickLoginBack={() => {
              registrationsTrack.backRegistrationsTrack({ buttonName: 'back' })
              setRegistrationsStatus(RegistrationsStatus.MAIN)
              setRegistrationsFillEmail(fillEmail)
            }}
          />
        )}
        {registrationsStatus === RegistrationsStatus.PASSWORD_LOGIN && (
          <RegistrationsPasswordLogin
            rsqlFillEmail={rsqlFillEmail || fillEmail}
            setFillEmail={setFillEmail}
            onPasswordLoginSuccess={() => onClose()}
            data={data?.registrationsPasswordLogin}
            gs={gs}
            onPasswordLoginBack={() => {
              if (registrationsOptions?.memberRegistration) {
                setRegistrationsStatus(RegistrationsStatus.MAIN)
                return
              }
              setRegistrationsStatus(RegistrationsStatus.QUICK_LOGIN)
            }}
            onSendVerifyEmailSuccess={(email) => {
              setFillEmail(email)
              setBackType('passwordLogin')
              setRegistrationsStatus(RegistrationsStatus.ACTIVATE)
            }}
          />
        )}
        {registrationsStatus === RegistrationsStatus.COUPONS && (
          <RegistrationsCoupons
            data={data?.registrationsCoupons}
            gs={gs}
            registerEmail={fillEmail}
            coupons={coupons}
            onCouponsGet={() => {
              if (profile?.activated) {
                onClose(registrationsStatus)
                return
              }
              setRegistrationsStatus(RegistrationsStatus.AUTH_CODE)
            }}
            onCouponsBack={() =>
              setRegistrationsStatus(RegistrationsStatus.MAIN)
            }
            showGoogleLoginCoupon={showGoogleLoginCoupon}
          />
        )}
        {registrationsStatus === RegistrationsStatus.AUTH_CODE && (
          <RegistrationsAuthCode
            data={data?.registrationsAuthCode}
            gs={gs}
            locale={locale}
            registerEmail={fillEmail}
            onAuthCodeSuccess={() => {
              handleSetFlag()
              setRegistrationsStatus(RegistrationsStatus.AUTH_CODE_SUCCESS)
            }}
          />
        )}
        {registrationsStatus === RegistrationsStatus.AUTH_CODE_SUCCESS && (
          <RegistrationsAuthCodeSuccess
            data={data?.registrationsAuthCodeSuccess}
            gs={gs}
            onLoadingAuthState={() =>
              onClose(RegistrationsStatus.AUTH_CODE_SUCCESS)
            }
          />
        )}
        {registrationsStatus === RegistrationsStatus.REMAINDER && (
          <RegistrationsRemainder
            data={data?.registrationsRemainder}
            gs={gs}
            rsrFillEmail={rsrFillEmail}
            onReminderBack={() => {
              setFillEmail(rsrFillEmail)
              setRegistrationsFillEmail('')
              setRegistrationsStatus(RegistrationsStatus.QUICK_LOGIN)
            }}
            onReminderGet={() => onClose()}
          />
        )}
        {registrationsStatus === RegistrationsStatus.SUBSCRIBE_SMS && (
          <RegistrationsSubscribeSMS
            data={data?.registrationsSubscribeSMS}
            gs={gs}
            subscribe={subscribe}
            registerEmail={fillEmail}
            onSubscribeSMSSkip={() => {
              setRegistrationsStatus(RegistrationsStatus.COUPONS)
            }}
            onSubscribeSMSSuccess={() => {
              setRegistrationsStatus(RegistrationsStatus.COUPONS)
            }}
            onActivateGet={() => onClose()}
          />
        )}
        {registrationsStatus === RegistrationsStatus.ACTIVATE && (
          <RegistrationsActivate
            data={data?.registrationsActivate}
            gs={gs}
            registerEmail={fillEmail}
            onActivateBack={() => {
              setRegistrationsStatus(
                backType === 'main'
                  ? RegistrationsStatus.MAIN
                  : RegistrationsStatus.PASSWORD_LOGIN
              )
              setRegistrationsFillEmail(fillEmail)
            }}
            onActivateGet={() => onClose()}
          />
        )}
        {registrationsStatus === RegistrationsStatus.ACTIVATE_NO_BACK_BTN && (
          <RegistrationsActivate
            data={data?.registrationsActivate}
            gs={gs}
            registerEmail={fillEmail}
            onActivateGet={() => onClose()}
            hideBackBtn
          />
        )}
      </div>
    </div>
  )
}

export default RegistrationsModel

const CloseIcon = ({ onClose }) => {
  return (
    <svg
      onClick={onClose}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8525 5.31015C16.4558 4.91337 15.8125 4.91338 15.4157 5.31015L11.1268 9.5991L6.83815 5.31049C6.44138 4.91372 5.79808 4.91372 5.40131 5.31049C5.00454 5.70726 5.00455 6.35055 5.40131 6.74732L9.68993 11.0359L5.40105 15.3248C5.00428 15.7216 5.00428 16.3649 5.40105 16.7616C5.79782 17.1584 6.44111 17.1584 6.83788 16.7616L11.1268 12.4728L15.416 16.762C15.8128 17.1588 16.456 17.1588 16.8528 16.762C17.2496 16.3652 17.2496 15.7219 16.8528 15.3252L12.5636 11.0359L16.8525 6.74698C17.2493 6.35021 17.2493 5.70692 16.8525 5.31015ZM16.921 6.02856C16.921 6.22992 16.8441 6.43127 16.6905 6.5849L14.465 8.8104L12.2395 11.0359L12.2395 11.0359L16.6905 6.58493C16.8441 6.43129 16.921 6.22993 16.921 6.02856ZM5.34966 6.19087C5.37992 6.3354 5.45115 6.47307 5.56336 6.58528L10.014 11.0359L10.014 11.0359L5.56336 6.58524C5.45116 6.47304 5.37992 6.33539 5.34966 6.19087ZM5.34682 16.1923C5.37547 16.3415 5.44757 16.484 5.56309 16.5996C5.87037 16.9068 6.36856 16.9068 6.67583 16.5996L11.1268 12.1486L15.578 16.5999C15.8853 16.9072 16.3835 16.9072 16.6908 16.5999C16.8462 16.4445 16.923 16.2402 16.9212 16.0365C16.923 16.2402 16.8462 16.4445 16.6908 16.5999C16.3835 16.9072 15.8853 16.9072 15.578 16.5999L11.1268 12.1487L6.67583 16.5996C6.36856 16.9069 5.87037 16.9069 5.56309 16.5996C5.44756 16.4841 5.37547 16.3415 5.34682 16.1923Z"
        fill="currentColor"
      />
    </svg>
  )
}
