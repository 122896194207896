import { useState, useEffect } from 'react'
import getProductsByQuery from '../../lib/apis/get-pruducts-campaign'
import { normalizeProduct } from '../../shopify/utils'
import { Product } from '@shopify/schema'

// 自定义Hook useProducts
const useProducts = (q: string, locale: string, locales: any) => {
  const [products, setProducts] = useState([]) // 用于存储请求结果的状态
  const [loading, setLoading] = useState(false) // 用于表示加载状态的状态
  const [error, setError] = useState<unknown>(null) // 用于存储可能发生的错误

  useEffect(() => {
    // 定义一个异步函数来执行请求
    const fetchProducts = async (
      q: string | undefined,
      locale: string,
      locales: undefined
    ) => {
      setLoading(true) // 开始请求时设置加载状态为true

      if (!q || q?.length == 0) {
        return { products: [], loading: false, error: 'no query' }
      }
      try {
        //@ts-ignore
        const { data } = await getProductsByQuery(q, {
          locale,
          locales,
        })
        // 请求成功后，使用normalizeProduct处理数据，并更新状态
        setProducts(
          data?.products?.edges
            .filter(
              (it: { node: Product }) =>
                q.includes(it.node.handle) &&
                it.node.availableForSale &&
                (it.node.totalInventory ?? 0) > 0
            )
            .map((item: { node: Product }) => normalizeProduct(item.node)) || []
        )
      } catch (error) {
        console.error('Error fetching products:', error)
        setError(error) // 如果发生错误，更新错误状态
      } finally {
        setLoading(false) // 请求完成后，无论成功或失败，都设置加载状态为false
      }
    }

    // 执行定义的异步函数
    fetchProducts(q, locale, locales)
  }, [q, locale]) // 依赖项数组，只有当q或locale变化时才重新执行请求

  // 返回请求结果、加载状态和错误状态
  return { products, loading, error }
}

export default useProducts
