import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import s from './index.module.css'
import { SHORT_LOCALE_MAP } from '@shopify/const'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import getPageByHandleQuery from '@shopify/utils/queries/get-page-query-by-handle'
import getProductsByQuery from '@shopify/utils/queries/get-product-query'
import getCollectionQuery from '@shopify/utils/queries/get-collection-query'
import getBlogQuery from '@shopify/utils/queries/get-blog-query'
import getArticleQuery from '@shopify/utils/queries/get-article-query'
import Icon from '@components/icons'
import Cookies from 'js-cookie'
import { languageMap } from '@commerce/seo/use-hreflang'
import { useGlobal } from '@components/common/GlobalContext'

/**
 * Renders a locale switcher component.
 *
 * @component
 * @param {Object} localeswitcher - The localeswitcher object containing country information.
 * @returns {JSX.Element|null} The rendered locale switcher component.
 */
const LocaleSwitcher = ({ localeswitcher, page, slug }) => {
  const [show, setShow] = useState(true)
  const [ipLocale, setIpLocale] = useState('')
  const { locale, asPath, route, push, query } = useRouter()
  const [autoJump, setAutoJump] = useState(false)
  const [href, setHref] = useState('')
  const { geoCode } = useGlobal()

  const specialLocale = useMemo(() => {
    return [{ gb: 'uk' }]
  }, [])

  const languageLocale = () => {
    const userLanguage = navigator.language?.toLocaleLowerCase()
    const foundKey = Object.entries(languageMap).find(
      ([, value]) => value?.toLocaleLowerCase() === userLanguage
    )
    return foundKey ? foundKey[0] : navigator.language
  }

  const getGeolocation = useCallback(async () => {
    if (geoCode) {
      let codeLocale = geoCode

      // 遍历 SHORT_LOCALE_MAP
      Object.entries(SHORT_LOCALE_MAP).forEach(([key, value]) => {
        if (value === geoCode) {
          codeLocale = key
        }
      })

      // 检查 localeswitcher 的 eu_country 和 de_country
      const geoCodeLower = geoCode.toLowerCase()
      if (
        localeswitcher?.eu_country?.some(
          (item) => item.toLowerCase() === geoCodeLower
        )
      ) {
        codeLocale = 'eu-en'
      } else if (
        localeswitcher?.de_country?.some(
          (item) => item.toLowerCase() === geoCodeLower
        )
      ) {
        codeLocale = 'eu-de'
      } else if (
        localeswitcher?.uk_country?.some(
          (item) => item.toLowerCase() === geoCodeLower
        )
      ) {
        codeLocale = 'uk'
      }

      // 遍历 specialLocale
      Object.entries(specialLocale).forEach(([key, value]) => {
        if (value[geoCode]) {
          codeLocale = value[geoCode]
        }
      })

      // 检查 localeswitcher 的 country_text
      if (
        !localeswitcher?.country_text[
          SHORT_LOCALE_MAP[codeLocale] || codeLocale
        ]
      ) {
        codeLocale = languageLocale()
      }
      setIpLocale(codeLocale)
      const { [locale]: storedLocale } = JSON.parse(
        localStorage.getItem('localeswitcher') ?? '{}'
      )
      if (storedLocale === codeLocale) {
        setAutoJump(true)
      }
    }
  }, [
    geoCode,
    locale,
    localeswitcher?.country_text,
    localeswitcher?.de_country,
    localeswitcher?.eu_country,
    localeswitcher?.uk_country,
    specialLocale,
  ])

  useEffect(() => {
    const localeswitcher_off = JSON.parse(
      localStorage.getItem('localeswitcher_off') || '{}'
    )
    if (
      (localeswitcher_off[locale] || Cookies.get(`localeswitcher_${locale}`)) &&
      query.autojump !== 'true'
    ) {
      setShow(false)
      return
    }
    getGeolocation()
  }, [getGeolocation, locale, query.autojump])

  useEffect(() => {
    if (query.autojump === 'true') {
      setAutoJump(true)
    }
  }, [query.autojump])

  const country = useMemo(() => {
    if (!ipLocale) return null
    let shortLocale = SHORT_LOCALE_MAP[ipLocale] || ipLocale
    return localeswitcher?.country_text[shortLocale]
  }, [ipLocale, localeswitcher?.country_text])

  const getLocalePage = useCallback(async () => {
    if (
      !ipLocale ||
      !localeswitcher?.country_text[SHORT_LOCALE_MAP[ipLocale] || ipLocale]
    )
      return
    let href = '/'
    const handle =
      page?.handle || slug || asPath?.split('?')[0].split('/').pop()
    if (handle === '/' || !handle) {
      if (ipLocale !== 'us') {
        href = '/' + ipLocale
      }
      setHref(href)
      return
    }

    const fetchAndSetHref = async (route, query, variables) => {
      const { res } = await fetchGraphqlApi({
        locale: ipLocale,
        query,
        variables,
      })

      switch (route) {
        case '/products/[slug]':
          if (res.productByHandle?.handle) {
            return `/products/${handle}`
          }
          break
        case '/collections/[category]':
          if (res.collectionByHandle?.handle) {
            return (
              res.collectionByHandle?.MF_path?.value || `/collections/${handle}`
            )
          }
          break
        case '/blogs/[blog]':
          if (res.blogByHandle?.handle) {
            return `/blogs/${handle}`
          }
          break
        case '/blogs/[blog]/[slug]':
          if (res.blog?.article?.handle) {
            return `/blogs/${res.blog?.handle}/${res.blog?.article?.handle}`
          }
          break
        case '/[...pages]':
          if (res.page?.handle) {
            return res.page?.MF_path?.value || `/${handle}`
          }
          break
      }
      return null
    }

    const routeMap = {
      '/products/[slug]': {
        query: getProductsByQuery,
        variables: { slug: query.slug },
      },
      '/collections/[category]': {
        query: getCollectionQuery,
        variables: { slug: query.category },
      },
      '/blogs/[blog]': {
        query: getBlogQuery,
        variables: { slug: query.blog },
      },
      '/blogs/[blog]/[slug]': {
        query: getArticleQuery,
        variables: { blog: query.blog, slug: query.slug },
      },
      '/[...pages]': {
        query: getPageByHandleQuery,
        variables: { handle: handle.replace('/', '') },
      },
    }

    if (routeMap[route]) {
      href =
        (await fetchAndSetHref(
          route,
          routeMap[route].query,
          routeMap[route].variables
        )) || href
    }

    if (ipLocale !== 'us') {
      href = `/${ipLocale}${href}`
    }
    setHref(href)
  }, [
    asPath,
    ipLocale,
    localeswitcher?.country_text,
    page?.handle,
    query.blog,
    query.category,
    query.slug,
    route,
    slug,
  ])

  useEffect(() => {
    if (locale !== ipLocale && autoJump && href) {
      location.href = `${href}?ref=country_switch_auto_from_${locale}`
    }
  }, [autoJump, href, locale, ipLocale, push])

  useEffect(() => {
    if (ipLocale && ipLocale !== locale) {
      getLocalePage()
    }
  }, [ipLocale, locale, getLocalePage])

  const confirm = () => {
    const localeswitcher = JSON.parse(
      localStorage.getItem('localeswitcher') || '{}'
    )
    localeswitcher[locale] = ipLocale
    localStorage.setItem('localeswitcher', JSON.stringify(localeswitcher))
  }

  const close = () => {
    const cookieOptions = {
      domain: location.hostname,
      expires: localeswitcher.close_cache || 14,
    }
    Cookies.set(`localeswitcher_${locale}`, 'true', cookieOptions)
    setShow(false)
  }

  if (!country || !show || ipLocale === locale || autoJump) return null

  return (
    <div className={cn('layer border-b border-[#e8e8e8] bg-white', s.flash)}>
      <div className="content">
        <div className="relative z-[51] py-[14px] text-[15px] font-medium text-[#333] min-l:flex min-l:min-h-[65px] min-l:items-center min-l:justify-between min-l:py-0">
          <div className="flex items-center justify-between gap-6 min-l:block">
            <p className="text-xs min-l:text-[15px]">{country.title}</p>
            <div className="min-l:hidden">
              <Icon
                iconKey="closev3"
                className="h-5 w-5 cursor-pointer text-black transition hover:text-[#555]"
                onClick={close}
              />
            </div>
          </div>
          <div className="mt-3 flex items-center gap-2 min-l:mt-0">
            <div className="flex h-[37px] min-w-[251px] flex-grow items-center text-ellipsis whitespace-nowrap rounded bg-[#f6f6f6] px-[10px] min-l:flex-grow-0">
              {country.code}
            </div>
            <a
              href={
                country.href
                  ? `${country.href}?ref=country_switch_popup_from_${locale}`
                  : `${href}?ref=country_switch_popup_from_${locale}`
              }
              rel={country.href ? 'noopener noreferrer nofollow' : ''}
              target={country.href ? '_blank' : '_self'}
              onClick={confirm}
              className="flex h-[37px] items-center justify-center rounded bg-[#333] px-3 text-white transition hover:bg-[#555]"
            >
              {country.continue}
            </a>
            <div className="hidden pl-2 min-l:block">
              <Icon
                iconKey="closev3"
                className="h-5 w-5 cursor-pointer text-black transition hover:text-[#555]"
                onClick={close}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocaleSwitcher
