import RegistrationsModel from './components/RegistrationsModel'
import { useState, useCallback, useEffect, useMemo } from 'react'
import s from './index.module.css'
import useCustomer from '@shopify/customer/use-customer'
import registrationsTrack from './RegistrationsTrack'
import { RegistrationsStatus, getAppName } from './const'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui/context'
import Cookies from 'js-cookie'
import { getUniqueListBy, getURLParameter } from '@lib/utils/tools'
import { getAdCookie } from '@lib/utils/cookies'
import fetcher from '@lib/fetcher'
import AppLoading from '../Modal/AppLoading'
import { useModal } from '@ebay/nice-modal-react'
import { useProfile } from '../ProfileContext'
import MemberTipBox from '../Modal/MemberTipBox'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { cartAttributesUpdateFn } from 'lib/shopify/api/cart/use-update-attributes'
import { useCart } from 'lib/shopify/api/cart'

const COUNTRY = {
  us: 'US',
  ca: 'CA',
  'eu-de': 'DE',
  'eu-en': 'AT',
  uk: 'GB',
  nl: 'LU',
  'eu-fr': 'FR',
  'eu-it': 'IT',
  'eu-es': 'ES',
  'cz-en': 'CZ',
}

const Registrations = ({
  registrations,
  showRegistrationsPopup,
  path,
  pathname,
  registrationsModalMetafields,
  globalCountry,
  showGoogleIdentity,
  memberSetting = { memberSetting },
}) => {
  const { data: customer } = useCustomer()
  const { data: cart } = useCart()
  const { locale, query, route } = useRouter()
  const email = useMemo(() => (customer ? customer?.email : ''), [customer])
  const [showRegistrationsPop, setShowRegistrationsPop] = useState(false)
  const {
    setRegistrationsPopup,
    leftBarShow,
    recaptchaReady,
    registrationsOptions,
    setRegistrationsOptions,
    setRegistrationsModelStatus,
  } = useUI()
  const [isGoogleLoginNew, setIsGoogleLoginNew] = useState(false)
  const [showGoogleLoginCoupon, setShowGoogleLoginCoupon] = useState(false)
  const [googleLoginCoupons, setGoogleLoginCoupons] = useState([])
  const appLoading = useModal(AppLoading)
  const memberTipBox = useModal(MemberTipBox)
  const { profile, isFinish } = useProfile()

  const page_group = useMemo(() => {
    switch (route) {
      case '/':
        return 'Home Page'
      case '/[...pages]':
        return `Activity Page_${query.pages?.[0]}`
      case '/products/[slug]':
        return `Product Detail Page_${query.slug}`
      default:
        return ''
    }
  }, [query.pages, query.slug, route])

  useEffect(() => {
    setIsGoogleLoginNew(getURLParameter('isNew') === 'true')
  }, [])

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const activities = useCallback(async () => {
    const { register_source } = getAdCookie()
    let url = '/api/multipass/rainbowbridge/activities'
    const result = await fetcher({
      action: 'activity',
      needRecaptcha: true,
      locale,
      url,
      method: 'POST',
      headers: {
        'X-Application-Name': `${app}-shopify`,
        'x-application-token': Cookies.get(`${app}-info`),
      },
      body: {
        email,
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: 'eufy_register_and_subscribe',
        promise_subscribe: false,
      },
    })
    if (!result.errors) {
      setGoogleLoginCoupons(result.data?.coupons || [])
      setShowGoogleLoginCoupon(true)
      const urlObj = new URL(location.href)
      urlObj.searchParams.delete('isNew')
      history.replaceState({}, '', urlObj.toString())
    }
  }, [app, email, locale])

  useEffect(() => {
    if (showGoogleIdentity && isGoogleLoginNew && email && recaptchaReady) {
      activities()
    }
  }, [activities, email, isGoogleLoginNew, recaptchaReady, showGoogleIdentity])

  const handleRegistrationsModelClose = useCallback(
    (status) => {
      switch (status) {
        case RegistrationsStatus.QUICK_LOGIN:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'login_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.REMAINDER:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.MAIN:
          setRegistrationsOptions({})
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.PASSWORD_LOGIN:
          setRegistrationsOptions({})
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'login_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.COUPONS:
          if (profile?.activated) {
            setShowGoogleLoginCoupon(false)
          }
          registrationsTrack.closeTrack({
            position: 'success',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.AUTH_CODE:
          registrationsTrack.closeTrack({
            position: 'success',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.AUTH_CODE_SUCCESS:
          registrationsTrack.closeTrack({
            position: 'success',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })

          if (showGoogleLoginCoupon) {
            setShowGoogleLoginCoupon(false)
            setRegistrationsPopup(false)
            setShowRegistrationsPop(false)
            return
          }
          break
        default:
          break
      }
      setRegistrationsPopup(false)
      setShowRegistrationsPop(false)
    },
    [
      profile?.activated,
      setRegistrationsOptions,
      setRegistrationsPopup,
      showGoogleLoginCoupon,
    ]
  )

  const showRegistrationsModal = useMemo(() => {
    let result = false
    if (
      // 系列页
      registrationsModalMetafields?.collections_handles &&
      path?.includes('collections')
    ) {
      const paths = path.toLowerCase().split('/')
      for (const collection_handle of registrationsModalMetafields?.collections_handles) {
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === collection_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!collection_handle && temp
        if (result) break
      }
    }
    // blog页
    if (
      registrationsModalMetafields?.blogs_handles &&
      path?.includes('blogs')
    ) {
      const paths = path.toLowerCase().split('/')
      for (const blog_handle of registrationsModalMetafields.blogs_handles) {
        if (blog_handle === 'home' && pathname === '/blogs') {
          result = true
        } else {
          let temp = false
          for (const pathItem of paths) {
            temp = pathItem.toLowerCase() === blog_handle.toLowerCase()
            if (temp) {
              break
            }
          }
          result = !!blog_handle && temp
        }
        if (result) break
      }
    }
    // page页
    if (
      registrationsModalMetafields?.pages_handles &&
      pathname === '/[...pages]'
    ) {
      const paths = path.toLowerCase().split('/')
      for (const pages_handle of registrationsModalMetafields?.pages_handles) {
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === pages_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!pages_handle && temp
        if (result) break
      }
    }
    // 产品
    if (registrationsModalMetafields?.handles && path?.includes('products')) {
      const paths = path.toLowerCase().split('/')
      for (const handle of registrationsModalMetafields?.handles) {
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!handle && temp
        if (result) break
      }
    }

    // 首页默认展示
    if (path === '/') {
      result = true
    }

    // 全局开关, 全站设置关闭即不展示
    if (registrationsModalMetafields?.storeFrontIndex === false) {
      result = false
    }

    return result
  }, [registrationsModalMetafields, path, pathname])

  // 设置Cookie
  const setItemWithExpiry = useCallback((key, value, timestamp, domain) => {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + timestamp, // 设置过期时间
      timestamp,
    }
    Cookies.set(key, item, {
      domain,
      expires: 30,
    })
  }, [])

  // 获取Cookie
  const getItemWithExpiry = useCallback((key, domain) => {
    const itemString = Cookies.get(key)
    if (!itemString) {
      return null
    }
    const item = JSON.parse(itemString)
    const now = new Date()

    // 检查是否过期
    if (now.getTime() > item.expiry) {
      Cookies.remove(key, {
        domain,
        expires: 30,
      })
      return null
    }
    return item
  }, [])

  const getUserPlus = useCallback(async () => {
    let isMonthlyPlus = false
    let isAnnualPlus = false
    try {
      const response = await fetcher({
        url: '/api/multipass/subsrv/v1/prime/member/info',
        body: '',
        locale,
        headers: {},
        repeatKey: '',
        type: '',
      })
      const memberList = response?.data?.data?.list
      const isMonthlyPlus = memberList?.some(
        (item) => item.prime_type === 1 && item.status
      )
      const isAnnualPlus = memberList?.some(
        (item) => item.prime_type === 2 && item.status
      )
      return { isMonthlyPlus, isAnnualPlus }
    } catch (e) {}
    return { isMonthlyPlus, isAnnualPlus }
  }, [locale])

  const showMemberTipBox = useCallback(
    (options, confirmUrl, cancelUrl, memberType) => {
      appLoading.hide()
      memberTipBox.show({
        options,
        title: memberSetting?.[options]?.title,
        content: memberSetting?.[options]?.content,
        confirmBtn: memberSetting?.[options]?.confirmBtn,
        confirmUrl,
        memberType: memberType || (registrationsOptions?.monthlyPlus ? 1 : 2),
        monthly: memberSetting?.memberSuperposed?.monthly,
        annual: memberSetting?.memberSuperposed?.annual,
        cancelUrl,
        ...(options === 'memberSuperposed' && {
          cancelBtn: memberSetting?.memberSuperposed?.cancelBtn,
        }),
      })
    },
    [appLoading, memberSetting, memberTipBox, registrationsOptions?.monthlyPlus]
  )

  const passportLoginMember = useCallback(async () => {
    const { loginRedirectUrl, monthlyPlus, annualPlus, sendAuthCodeSuccess } =
      registrationsOptions || {}
    let passportLoginUrl = Cookies.get('passport-login-url')
    if (!passportLoginUrl) return
    const hostUrl = window.location.host.split('.')
    const domain = window.location.host.includes('localhost')
      ? 'localhost'
      : `.${hostUrl[1]}.${hostUrl[2]}`
    const { isMonthlyPlus, isAnnualPlus } = await getUserPlus()
    const urlObj = new URL(passportLoginUrl)
    if (loginRedirectUrl) {
      let loginRedirectUrlObj = new URL(loginRedirectUrl)
      if (sendAuthCodeSuccess) {
        loginRedirectUrlObj.searchParams.set('ref', 'activate_member')
      }
      urlObj.searchParams.set('redirect', loginRedirectUrlObj.toString())
    }
    if ((monthlyPlus && isMonthlyPlus) || (annualPlus && isAnnualPlus)) {
      showMemberTipBox(
        'memberCannotSuperposed',
        passportLoginUrl,
        passportLoginUrl,
        monthlyPlus ? 1 : 2
      )
      return
    }
    if (
      (registrationsOptions?.monthlyPlus && isAnnualPlus) ||
      (registrationsOptions?.annualPlus && isMonthlyPlus)
    ) {
      showMemberTipBox(
        'memberSuperposed',
        urlObj.toString(),
        passportLoginUrl,
        monthlyPlus ? 1 : 2
      )
      return
    }
    Cookies.remove('passport-login-url', {
      domain,
      expires: 30,
    })
    window.location.href = urlObj.toString()
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'begin_checkout',
      event_parameters: {
        page_group,
      },
      position: registrationsOptions?.checkout
        ? 'Cart Checkout'
        : 'Card Pop Up',
      button_name: registrationsOptions?.button,
      currency: registrationsOptions?.cart?.currency?.code,
      value: registrationsOptions?.cart?.totalPrice,
      items: registrationsOptions?.cart?.lineItems?.map((item) => ({
        item_id: item.variant?.sku, //产品SKU
        item_name: item.product?.title,
        item_brand: process.env.NEXT_PUBLIC_BRAND || 'eufy',
        item_variant: item.variant.name,
        price: item.totalAmount,
        quantity: item.quantity | 1,
      })),
    })
  }, [getUserPlus, page_group, registrationsOptions, showMemberTipBox])

  // 设置是否弹窗标识
  useEffect(() => {
    const hostUrl = window.location.host.split('.')
    const domain = window.location.host.includes('localhost')
      ? 'localhost'
      : `.${hostUrl[1]}.${hostUrl[2]}`

    const registrations_pop_up = getItemWithExpiry(
      `${COUNTRY?.[locale]}_registrations_pop_up`,
      domain
    )

    if (showRegistrationsPopup) {
      registrationsTrack.pvTrack()
    }

    if (!registrations_pop_up) {
      const timer = setTimeout(() => {
        if (!email && showRegistrationsModal) {
          registrationsTrack.pvTrack()
          setShowRegistrationsPop(true)
          setItemWithExpiry(
            `${COUNTRY?.[locale]}_registrations_pop_up`,
            true,
            14 * 24 * 60 * 60 * 1000,
            domain
          )
        }
        clearTimeout(timer)
      }, 10000)
    }

    // 存在用户直接刷新页面的情况，重定向到passport，带上登录态
    let passportLoginUrl = Cookies.get('passport-login-url')
    if (passportLoginUrl) {
      if (
        registrationsOptions?.loginRedirectUrl &&
        (profile?.activated || registrationsOptions?.sendAuthCodeSuccess)
      ) {
        passportLoginMember()
      } else if (!showRegistrationsPopup) {
        appLoading.show()
        window.location.href = passportLoginUrl
        Cookies.remove('passport-login-url', {
          domain,
          expires: 30,
        })
      }
    }
  }, [
    email,
    getItemWithExpiry,
    locale,
    passportLoginMember,
    profile?.activated,
    registrationsOptions?.loginRedirectUrl,
    registrationsOptions?.sendAuthCodeSuccess,
    setItemWithExpiry,
    showRegistrationsModal,
    appLoading,
    showRegistrationsPopup,
  ])

  const getCustomAttribute = useCallback(
    (attributes) => {
      return getUniqueListBy(
        [...(cart?.customAttributes || []), ...(attributes || [])],
        'key'
      ).filter((item) => item.value)
    },
    [cart?.customAttributes]
  )

  const begin_checkout = useCallback(() => {
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'begin_checkout',
      event_parameters: {
        page_group,
      },
      position: registrationsOptions?.checkout
        ? 'Cart Checkout'
        : 'Card Pop Up',
      button_name: registrationsOptions?.button,
      currency: registrationsOptions?.cart?.currency?.code,
      value: registrationsOptions?.cart?.totalPrice,
      items: registrationsOptions?.cart?.lineItems?.map((item) => ({
        item_id: item.variant?.sku, //产品SKU
        item_name: item.product?.title,
        item_brand: process.env.NEXT_PUBLIC_BRAND || 'eufy',
        item_variant: item.variant.name,
        price: item.totalAmount,
        quantity: item.quantity | 1,
      })),
    })
  }, [
    page_group,
    registrationsOptions?.button,
    registrationsOptions?.cart?.currency?.code,
    registrationsOptions?.cart?.lineItems,
    registrationsOptions?.cart?.totalPrice,
    registrationsOptions?.checkout,
  ])

  const thirdLoginMember = useCallback(async () => {
    const loginRedirectMember = sessionStorage.getItem('loginRedirectMember')
    if (!loginRedirectMember) return
    const { loginRedirectUrl, monthlyPlus, annualPlus, cartId } =
      JSON.parse(loginRedirectMember)
    sessionStorage.removeItem('loginRedirectMember')
    const token = Cookies.get(`${app}-info`)
    await cartAttributesUpdateFn(
      locale,
      getCustomAttribute([
        {
          key: '_token',
          value: token,
        },
      ]),
      cartId
    )

    const { activated, isMonthlyPlus, isAnnualPlus } = profile

    if (!activated) {
      setRegistrationsPopup(true)
      setRegistrationsModelStatus(RegistrationsStatus.AUTH_CODE)
      return
    }

    const isPlusMember =
      (monthlyPlus && isMonthlyPlus) || (annualPlus && isAnnualPlus)
    const isSuperposedMember =
      (monthlyPlus && isAnnualPlus) || (annualPlus && isMonthlyPlus)

    if (isPlusMember) {
      showMemberTipBox(
        'memberCannotSuperposed',
        undefined,
        undefined,
        monthlyPlus ? 1 : 2
      )
      return
    }
    begin_checkout()
    if (isSuperposedMember) {
      showMemberTipBox(
        'memberSuperposed',
        loginRedirectUrl,
        undefined,
        monthlyPlus ? 1 : 2
      )
      return
    }
    if (!isMonthlyPlus && !isAnnualPlus) {
      location.href = loginRedirectUrl
    }
  }, [
    app,
    locale,
    getCustomAttribute,
    profile,
    begin_checkout,
    setRegistrationsPopup,
    setRegistrationsModelStatus,
    showMemberTipBox,
  ])

  useEffect(() => {
    if (isFinish && profile) {
      thirdLoginMember()
    }
  }, [isFinish, profile, thirdLoginMember])

  const registrationsModalStatus = useMemo(() => {
    return (
      showRegistrationsPopup ||
      (!email && showRegistrationsPop) ||
      showGoogleLoginCoupon
    )
  }, [
    showRegistrationsPopup,
    email,
    showRegistrationsPop,
    showGoogleLoginCoupon,
  ])

  useEffect(() => {
    if (registrationsModalStatus) {
      document.querySelector('html').classList.add('fw-hidden')
    } else if (!leftBarShow) {
      document.querySelector('html').classList.remove('fw-hidden')
    }
  }, [registrationsModalStatus, leftBarShow])

  return (
    registrationsModalStatus && (
      <>
        <div aria-label="mask" className={s.rsm_close_mask}></div>
        <RegistrationsModel
          onClose={handleRegistrationsModelClose}
          gs={s}
          locale={locale}
          data={registrations}
          globalCountry={globalCountry}
          showGoogleLoginCoupon={showGoogleLoginCoupon}
          googleLoginCoupons={googleLoginCoupons}
        />
      </>
    )
  )
}

export default Registrations
