import styles from './index.module.css'
import cn from 'classnames'
import { Text } from '@components/ui'
import { useForm } from 'react-hook-form'
import { useCallback, useState } from 'react'
import { validate } from 'email-validator'
import RegistrationsThirdLogin from '../RegistrationsThirdLogin'
import { useRouter } from 'next/router'
import fetcher from '@lib/fetcher'
// start_ai_generated
import { getPassportSign } from '@lib/utils/tools'
import registrationsTrack from '../../RegistrationsTrack'
// end_ai_generated

const RegistrationsQuickLogin = ({
  data,
  gs,
  onPasswordLogin,
  onQuickLoginBack,
  onQuickLoginSuccess,
  onCreateAccount,
  fillEmail = '',
}) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [networkError, setNetworkError] = useState('')
  const { locale } = useRouter()
  const s = { ...styles, ...gs }
  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    defaultValues: { quickLogin: { email: fillEmail } },
  })

  const onSubmit = async (formData) => {
    if (Reflect.ownKeys(errors).length > 0) return
    setSubmitLoading(true)
    const { email } = formData?.quickLogin
    const bodyData = {
      email,
      sign: getPassportSign(email),
      data: btoa(
        JSON.stringify({
          redirect:
            location.href +
            (location.search ? location.search + '&' : '?') +
            'ref=quickloginauth_reg_sub_pop',
        })
      ),
    }
    const res = await fetcher({
      action: 'login',
      needRecaptcha: true,
      locale,
      url: '/api/multipass/account/quick_logins/login_token',
      method: 'POST',
      body: bodyData,
    }).finally(() => setSubmitLoading(false))
    if (res.status > 100 && res.status < 300) {
      registrationsTrack.loginTrack({ position: 'login_quick_login' })
      onQuickLoginSuccess && onQuickLoginSuccess(email)
    } else {
      // 邮箱不存在
      setNetworkError(data?.info_no_registered_email)
    }
  }

  const handleFocus = useCallback(() => {
    clearErrors('quickLogin[email]')
    setNetworkError('')
  }, [clearErrors])

  const formError = errors?.quickLogin?.email?.message || networkError

  return (
    <registrations-quick-login
      class={cn(s.registrations_quick_login, {
        [s.disabled]: submitLoading,
      })}
    >
      {/* <div
        className={cn(s.rsql_back, s.registrations_back)}
        onClick={onQuickLoginBack}
        tag="Auto-000006417"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7071 5.70711C15.0976 5.31658 15.0976 4.68342 14.7071 4.29289C14.3166 3.90237 13.6834 3.90237 13.2929 4.29289L7.29617 10.2896C7.29508 10.2907 7.29398 10.2918 7.29289 10.2929C7.10602 10.4798 7.00857 10.7222 7.00054 10.967C6.99179 11.2338 7.08924 11.5035 7.29289 11.7071C7.294 11.7082 7.29512 11.7093 7.29623 11.7104L13.2929 17.7071C13.6834 18.0976 14.3166 18.0976 14.7071 17.7071C15.0976 17.3166 15.0976 16.6834 14.7071 16.2929L9.41422 11L14.7071 5.70711Z"
            fill="#333333"
          />
        </svg>
      </div> */}
      <div
        className={cn(s.registrations_quick_login_wrap, s.registrations_wrap)}
      >
        <div className={s.registrations_quick_login_head}>
          <p
            className={cn(s.rsql_title, s.registrations_title)}
            tag="Auto-000006411"
          >
            {data.title}
          </p>
        </div>
        <div className={s.registrations_quick_login_body}>
          <form
            method="post"
            className={s.rsql_form}
            acceptCharset="UTF-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={s.rsql_form_item}>
              <input
                className={cn(s.rsql_input_email, s.registrations_input, {
                  [s.input_error]: formError,
                })}
                tag="Auto-000006412"
                name="quickLogin[email]"
                maxLength="200"
                onFocus={handleFocus}
                aria-label={data.email_placeholder}
                placeholder={data.email_placeholder}
                {...register('quickLogin[email]', {
                  required: data?.info_fill_email,
                  validate: (value) => validate(value) || data?.info_err_email,
                })}
              />
            </div>
            {formError && (
              <p className={s.form_error}>
                {formError}&nbsp;
                {networkError && (
                  <a
                    onClick={() =>
                      onCreateAccount(getValues('quickLogin[email]'))
                    }
                    className={s.rsql_create_account}
                    dangerouslySetInnerHTML={{
                      __html: data?.create_account_text,
                    }}
                  />
                )}
              </p>
            )}
            {submitLoading ? (
              <button
                className={cn(
                  s.rsql_login,
                  s.registrations_btn,
                  s.flex_center,
                  s.loading_btn
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            ) : (
              <button
                type="submit"
                tag="Auto-000006413"
                className={cn(s.rsql_login, s.registrations_btn, s.flex_center)}
              >
                {data.login_text}
              </button>
            )}
            <div tag="Auto-000006414">
              <Text
                variant="paragraph"
                html={data?.password_login_text}
                className={s.rsql_login_text}
                onClick={() =>
                  onPasswordLogin &&
                  onPasswordLogin(getValues('quickLogin[email]'))
                }
              />
            </div>
          </form>
        </div>
        <div className={s.registrations_quick_login_footer}>
          <RegistrationsThirdLogin data={data} gs={gs} />
        </div>
      </div>
    </registrations-quick-login>
  )
}

export default RegistrationsQuickLogin
