export const languageMap: { [key: string]: string } = {
  us: 'en-US',
  uk: 'en-GB',
  ca: 'en-CA',
  au: 'en-AU',
  'eu-en': 'en',
  'eu-fr': 'fr-FR',
  'eu-it': 'it-IT',
  'eu-de': 'de-DE',
  'eu-es': 'es-ES',
  'cz-en': 'en-CZ',
  nl: 'nl-NL',
  'dk-en': 'en-DK',
  'gr-en': 'en-GR',
  'mk-en': 'en-MK',
  'hu-en': 'en-HU',
  'se-en': 'en-SE',
  'hr-en': 'en-HR',
  'bg-en': 'en-BG',
  'ee-en': 'en-EE',
  'fi-en': 'en-FI',
  'lt-en': 'en-LT',
  'pl-en': 'en-PL',
  'ro-en': 'en-RO',
  'si-en': 'en-SI',
  'sk-en': 'en-SK',
  'no-en': 'en-NO',
  'ad-en': 'en-AD',
  'al-en': 'en-AL',
  'ba-en': 'en-BA',
  'me-en': 'en-ME',
  'rs-en': 'en-RS',
  'gl-en': 'en-GL',
  'is-en': 'en-IS',
  'ua-en': 'en-UA',
  'by-en': 'en-BY',
  'md-en': 'en-MD',
  'lv-en': 'en-LV',
  'at-de': 'de-AT',
  'lu-de': 'de-LU',
  'li-de': 'de-LI',
  'va-it': 'it-VA',
  'sm-it': 'it-SM',
  'be-nl': 'nl-BE',
}

export const blogsMap: { [key: string]: string } = {
  us: 'en',
  'eu-fr': 'fr',
  'eu-de': 'de',
  nl: 'nl',
}

export const languageTerritory = (locale: string) =>
  // https://unicode-org.github.io/cldr-staging/charts/37/supplemental/language_territory_information.html
  languageMap[locale] || 'en-US'
