import Cookies from 'js-cookie'

import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { Cart as CartType } from 'lib/shopify/types'
import { normalizeCart } from 'lib/utils/normalize'
import { createCart, syncCart } from 'lib/shopify/api/cart'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import {
  addToCartMutation,
  cartBuyerIdentityUpdate,
} from 'lib/shopify/mutations/cart'
import { SHOPIFY_CHECKOUT_ID_COOKIE } from 'lib/config/const'

// todo!
import { getPersonaCookie } from '@lib/utils/thirdparty'
import { useUI } from '@components/ui/context'
import { CommerceError } from '@commerce/utils/errors'
import { useCommerce } from '@commerce/index'
import { useCart } from './use-cart'
import { getAttributesFromLineItems } from './use-update-attributes'
import { getOrderCustomAttribute } from 'lib/utils/useCustomAttributes'

export async function addItemFn(
  locale: string = 'us',
  lines: CartType.InputLineItem[],
  id?: string,
  cartAttributes?: any,
  buyerIdentity?: any
) {
  let lineItems: any = []
  const addLine = (line: any) => {
    if (
      line.quantity &&
      (!Number.isInteger(line.quantity) || line.quantity! < 1)
    ) {
      throw new CommerceError({
        message: 'The item quantity has to be a valid integer greater than 0',
      })
    }

    if (line.sellingPlanId) {
      lineItems.push({
        merchandiseId: line.merchandiseId,
        quantity: line.quantity ?? 1,
        attributes: line?.attributes,
        sellingPlanId: line.sellingPlanId,
      })
    } else {
      lineItems.push({
        merchandiseId: line.merchandiseId,
        quantity: line.quantity ?? 1,
        attributes: line?.attributes,
      })
    }
  }
  if (Array.isArray(lines)) {
    lines.map((v, i) => {
      addLine(v)
    })
  } else {
    addLine(lines)
  }

  const personaCookie = getPersonaCookie()
  if (personaCookie) {
    lineItems = lineItems.map((line: CartType.InputLineItem) => ({
      ...line,
      attributes: [
        ...(line.attributes || []),
        { key: personaCookie, value: '-' },
      ],
    }))
  }

  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]
  const cartId = id || Cookies.get(checkoutIdCookie)
  const orderCustomAttribute = getOrderCustomAttribute({ locale })
  const LineItemsAttributes = getAttributesFromLineItems(lineItems)
  const cartCustomAttributes = cartAttributes ? cartAttributes : []
  const attributes = [
    ...orderCustomAttribute,
    ...LineItemsAttributes,
    ...cartCustomAttributes,
  ]

  if (!cartId) {
    const cart = await createCart(locale, lineItems, attributes, {
      override: true,
      buyerIdentity,
    })
    if (cart) syncCart(cart, locale)
    return cart && normalizeCart(cart)
  } else {
    let { res } = await fetchGraphqlApi<CartType.ShopifyAddToCartOperation>({
      locale,
      query: addToCartMutation,
      variables: {
        cartId,
        lines: lineItems,
        attributes,
      },
      cache: 'no-store',
    })

    if (res?.cartLinesAdd?.cart) {
      syncCart(res.cartLinesAdd.cart, locale)
    }

    let cart = res?.cartLinesAdd?.cart
    if (buyerIdentity?.deliveryAddressPreferences?.[0]) {
      const { res } =
        await fetchGraphqlApi<CartType.ShopifyBuyerIdentityUpdateCartOperation>(
          {
            locale,
            query: cartBuyerIdentityUpdate,
            variables: {
              cartId,
              buyerIdentity,
            },
            cache: 'no-store',
          }
        )
      cart = res?.cartBuyerIdentityUpdate?.cart || cart
    }
    return normalizeCart(cart)
  }
}

export function useAddItem(id?: string) {
  const { locale } = useRouter()

  const { mutate, data } = useCart()
  const { setTipModal } = useUI()
  const { shop } = useCommerce()

  const addItem = useCallback(
    async (
      input: {
        variantId: string
        quantity: number
        customAttributes?: any
        sellingPlanId?: string
      }[],
      attributes?: any,
      buyerIdentity?: any
    ) => {
      const orderLimits = shop?.orderLimits || {}

      // 新增是否超库存判断
      let result = true
      let inputs: any[] = Array.isArray(input) ? input : [input]
      const errors: Array<string> = []
      //shopify会对同一个商品不同活动时做拆分，这里需要汇总同一个sku的库存数
      const lines: Record<string, any> = {}
      data?.lineItems?.forEach((item: CartType.ExportLineItem) => {
        lines[item.variantId] = lines[item.variantId] || {
          quantity: 0,
          variant: {
            currentlyNotInStock: item?.variant?.currentlyNotInStock,
            quantityAvailable: item?.variant?.quantityAvailable,
            sku: item?.variant?.sku,
          },
          options: item.options,
          name: item?.name,
        }
        lines[item.variantId].quantity =
          lines[item.variantId].quantity + item.quantity
      })
      inputs?.forEach((input) => {
        const item = lines[input.variantId]
        if (item) {
          const variant = item?.variant
          //库存,如果允许超卖(允许超卖的商品也有9999限制,9999数可配置)
          const available = variant?.currentlyNotInStock
            ? orderLimits?.quantityMax || 9999
            : variant.quantityAvailable
          //根据配置判断最大加购
          const orderLimit =
            orderLimits?.limits?.[variant?.sku] || orderLimits?.limits?.['*']
          const limitMax = orderLimit?.max
          const max =
            limitMax !== undefined && limitMax <= available
              ? limitMax
              : available
          const sumQuantity = input.quantity + item.quantity
          //如果加购数量大于库存，则返回失败
          if (sumQuantity > max) {
            result = false
            let tips =
              orderLimits?.tips?.errorTip ||
              `<div class='txtCon'>{name},<b>Options</b>:{options}, Must have at most <b>{max}</b> of this item.</div>`

            const options = item?.options?.map((option: any) => {
              return `${option?.name}:${option?.value}`
            })
            tips = tips
              ?.replace('{name}', item?.name)
              ?.replace('{options}', options?.join(',') || item?.name)
              ?.replace('{max}', max)
            errors.push(tips)
          }
        }
      })

      // anker day 会员折扣统一处理
      let finialInput: any[] = (Array.isArray(input) ? input : [input]).map(
        (item) => ({
          merchandiseId: item.variantId,
          quantity: item.quantity,
          attributes: item.customAttributes,
          sellingPlanId: item.sellingPlanId,
        })
      )
      if (result) {
        const resultData = await addItemFn(
          locale,
          finialInput,
          id,
          attributes,
          buyerIdentity
        )
        await mutate(resultData, false)
        return {
          lines,
          result,
          resultData,
          oriCart: data,
          input,
          orderLimits,
        }
      } else {
        const errorMessage = `${
          orderLimits?.tips?.errorTitle ||
          'Cannot place order,conditions not met'
        }${errors?.join(',') || 'addToCart err'}`

        setTipModal(errorMessage)
        return null
        // throw new CommerceError({
        //   message: errorMessage,
        // })
      }
    },
    [data, locale, id, mutate, setTipModal, shop?.orderLimits]
  )

  return addItem
}
